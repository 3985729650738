<template>
    <div class="main">

        <img src="./sample-icon.png" alt="">
<!--        退货保障标题-->
        <div style="margin-left: 17px">
            <p class="policy-title">样品享受七天无条件退货保障！</p>
            <p class="policy-title2">(部分商品及食品类商品除外)</p>
        </div>

        <div style="width:2px;height:100%;background-color:#D8D8D8;margin: 0 10px;"></div>
<!--        拿样假规则-->
        <div class="content">
            <span >拿样价：</span>
            <span style="color: #FF9835;margin: 0 23px;">￥{{DiscountPrice}}</span>
            <span>运费：（采购满足条件者可退还相关运费）</span>
            <span style="margin-left: 25px">下单前请查看</span>
            <router-link
                 :to="{ path: '/cms/help-13' }">
                <span style="color: black!important;cursor: pointer;">某品牌拿样规则</span>
            </router-link>
        </div>
<!--我要看样-->
        <div style="margin-left: 25px">
            <button class="wyky" @click="showNotice" >我要看样</button>
        </div>
        <transition name="fade">
<!--            molsnotice:判断购物车是否加载完完毕，isShow判断组件是否显示,DiscountPrice显示商品的单价-->
            <notice class="notice"
                    :molsnotice="molsnotice"
                    :discount-price="DiscountPrice"
                    v-if="isShow"
                    @displayme="showNotice"
                    :join-cart="joinCart"
                    :current-sku-id="currentSkuId"
            />
        </transition>
    </div>
</template>

<script>
    import Notice from "@/components/notice.vue"
    export default {
        name: "ReturnPolicy",
        components: { Notice },
        props:{
            // 传入的等横线用驼峰接受了
            DiscountPrice:{
                type:String,
                require:true
            },
            joinCart:{
                type:Function,
                require: true
            },
            molsnotice:{
                type:Boolean,
                require:true
            },
            currentSkuId:{
                type:Number,
                require:true
            }
        },
        data() {
            return {
                isShow: false
            }
        },
        methods:{
            showNotice(){
                this.isShow=!this.isShow
            }


        }

    }

</script>

<style scoped lang="scss">
    .main{
        position: relative;
        width: 1200px;
        height: 70px;
        background: #F5F5F5;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .policy-title{
            width: 280px;
            height: 20px;
            font-size: 20px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 30px;
            margin-bottom: 10px;
        }
        .policy-title2{
            width: 178px;
            height: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            line-height: 21px;
        }
        .content span{
            display: inline-block;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-size: 12px;
            color: #999999;

        }
        .wyky{
            cursor: pointer;
            width: 128px;
            height: 40px;
            background: #FFF1E3;
            border: 1px solid #FF9835;
            font-size: 14px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FF6835;
            line-height: 21px;
        }

        .notice{
            position: absolute;top: 55px;right: 35px;z-index: 111;
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>