<template>
  <div class="goods-detail">
    <div class="clearfix" style="background: white;padding-top: 20px">
      <div class="clearfix" style="width:1200px;margin:0 auto;position: relative;min-height: 550px;">
        <bread-crumbs :goods_name="goodsName" :site-id="site_id" :molsloading="molsloading" />
        <!--        vue组件名字不支持驼峰-->
        <div class="preview-wrap">
          <div class="video-player-wrap" :class="{ show: switchMedia == 'video' }" v-if="goodsSkuDetail.video_url != ''">
            <video-player v-if="goodsSkuDetail.video_url != ''" ref="videoPlayer" :playsinline="true"
              :options="playerOptions" @play="onPlayerPlay($event)" @pause="onPlayerPause($event)"
              @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)" @playing="onPlayerPlaying($event)"
              @loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
              @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)"
              @statechanged="playerStateChanged($event)" @ready="playerReadied"></video-player>

            <div class="media-mode" v-if="goodsSkuDetail.video_url != ''">
              <span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">视频</span>
              <span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">图片</span>
            </div>
          </div>
          <!-- , { size: 'big' } -->
          <div class="magnifier-wrap">
            <pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
          </div>

          <div class="spec-items">
            <span class="left-btn iconfont iconarrow-left-copy" :class="{ move: moveThumbLeft }"
              style="background: #EEEEEE;border-radius: 4px;" @click="changeThumbImg('prev')"></span>
            <span style="background: #EEEEEE;border-radius: 4px;" class="right-btn iconfont iconarrow-right"
              :class="{ move: moveThumbRight }" @click="changeThumbImg('next')"></span>
            <ul :style="{ left: 30 + thumbPosition + 'px' }">
              <!-- 商品缩率图 -->
              <li v-for="(item, index) in goodsSkuDetail.sku_images" :key="index" @mousemove="picZoomUrl = item"
                :class="{ selected: picZoomUrl == item }">
                <img :src="$img(item, { size: 'small' })" @error="imageErrorSpec(index)" />
              </li>
            </ul>
          </div>

          <!--			<div class="share-collect">-->
          <!--				<div @click="editCollection">-->
          <!--					<i class="iconfont" :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlike'"></i>-->
          <!--					<span data-collects="0" class="focus-text" v-if="goodsSkuDetail.collect_num > 0">关注商品（{{ goodsSkuDetail.collect_num }}）</span>-->
          <!--					<span data-collects="0" class="focus-text" v-else>关注商品</span>-->
          <!--				</div>-->
          <!--				<div @click="service_link">-->
          <!--					<i class="iconfont iconzhanghao"></i>-->
          <!--					<span data-collects="0">联系客服</span>-->
          <!--				</div>-->

          <!--			</div>-->
        </div>
        <!-- 商品信息 -->
        <div class="basic-info-wrap" v-loading="loading">
          <h1>{{ goodsSkuDetail.sku_name }}</h1>
          <p class="desc ns-text-color" v-if="goodsSkuDetail.introduction">{{ goodsSkuDetail.introduction }}</p>

          <div class="discount-banner ns-bg-color"
            v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime && addonIsExit.discount">
            <div class="activity-name">
              <i class="discount-icon iconfont iconicon_naozhong"></i>
              <span>限时折扣</span>
            </div>
            <div class="surplus-time">
              <span>{{ discountText }}</span>
              <count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()"
                :currentTime="discountTimeMachine.currentTime" :startTime="discountTimeMachine.startTime"
                :endTime="discountTimeMachine.endTime" :dayTxt="'天'" :hourTxt="'小时'" :minutesTxt="'分钟'"
                :secondsTxt="'秒'"></count-down>

              <!-- :tipText="'距离开始文字1'"
              :tipTextEnd="'距离结束文字1'"
                        :endText="'结束自定义文字2'"-->


            </div>
          </div>

          <div class="item-block">
            <div class="promotion-price">
              <dl class="item-line">
                <dt class="ns-text-color-gray">商城价</dt>
                <dd>
                  <em class="yuan ns-text-color">¥</em>
                  <span class="price ns-text-color">{{ goodsSkuDetail.discount_price }}</span>
                </dd>
              </dl>
              <dl class="item-line" v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime">
                <dt class="ns-text-color-gray">原价</dt>
                <dd>
                  <em class="market-yuan">¥</em>
                  <span class="market-price">{{ goodsSkuDetail.price }}</span>
                </dd>
              </dl>
              <dl class="item-line" v-if="goodsSkuDetail.market_price > 0">
                <dt class="ns-text-color-gray">市场价</dt>
                <dd>
                  <em class="market-yuan">¥</em>
                  <span class="market-price">{{ goodsSkuDetail.market_price }}</span>
                </dd>
              </dl>

              <div class="statistical">
                <span>累计销量</span>
                <span style="color:red">&nbsp;&nbsp;{{ goodsSkuDetail.sale_num }}&nbsp;&nbsp;</span>
                <span>{{ goodsSkuDetail.unit }}</span>
              </div>
            </div>
          </div>

          <dl class="item-line service">
            <dt>促销</dt>
            <dd style="position: relative;line-height: 2;margin-top: 5px;">
              <p v-if="freeshipping">免运费（满{{ freeshipping.price }}元包邮）</p>
              <p v-if="addonIsExit.manjian && manjian.manjian_name">
                单笔订单
                <span v-for="(item, index) in manjian.rule_json" :key="index">满{{ item.money }}元，立减{{
                  item.discount_money
                }}元<template v-if="item.present">，送赠品</template>；</span>
              </p>
              <p @click="saleShow = true" style="cursor: pointer;">共{{ manjian.len }}项，展开查看 <i
                  class="el-icon-arrow-down"></i></p>

              <div v-if="saleShow" class="sale-rule-content">
                <div class="title"><span class="sale-name">满即送</span>共<strong>{{ manjian.len }}</strong>项，促销活动规则<a
                    @click="saleShow = false" style="cursor: pointer;" wttype="hide-rule">关闭</a></div>
                <div class="content">
                  <div class="mjs-tit">{{ manjian.manjian_name }}<time>(
                      活动时间：{{ manjian.start_time_str }}--{{ manjian.end_time_str }} )</time></div>
                  <ul class="mjs-info">
                    <li v-for="(item, index) in manjian.rule_json">
                      <span class="sale-rule">单笔订单满<em>{{ item.money }}</em>元， 立减<em>{{ item.discount_money }}</em>元， 送
                        <div v-if="item.coupon_data" class="mj-coupon" style="display: inline-block;">
                          <p class="ns-text-color">
                            <span class="ns-border-color" v-if="item.coupon_data.type == 'discount'">{{
                              item.coupon_data.discount }}折</span>
                            <span class="ns-border-color" v-if="item.coupon_data.type == 'reward'">￥{{
                              item.coupon_data.money }}</span>
                            <label v-if="item.coupon_data.type == 'discount'">{{ item.coupon_data.discount }}折</label>
                            <label v-if="item.coupon_data.type == 'reward'">￥{{ item.coupon_data.money }}</label>
                          </p>
                        </div>
                      </span>
                    </li>
                  </ul>
                  <div class="mjs-remark">活动多</div>
                </div>
              </div>

            </dd>
          </dl>
          <dl class="item-line delivery" v-if="goodsSkuDetail.is_virtual == 0">
            <dt>配送至</dt>
            <dd>
              <div class="region-selected ns-border-color-gray">
                <span>
                  <template v-if="selectedAddress['level_1']">
                    <template v-for="item in selectedAddress">
                      {{ item.name }}
                    </template>
                  </template>
                  <template v-else>
                    请选择配送地址
                  </template>
                </span>
                <i class="el-icon-arrow-down"></i>
              </div>
              有货
              <div class="region-list ns-border-color-gray" :class="{ hide: hideRegion }">
                <ul class="nav-tabs">
                  <li :class="{ active: currTabAddres == 'province' }" @click="currTabAddres = 'province'">
                    <div>
                      <span>{{ selectedAddress['level_1'] ? selectedAddress['level_1'].name : '请选择省' }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </li>
                  <li :class="{ active: currTabAddres == 'city' }" @click="currTabAddres = 'city'">
                    <div>
                      <span>{{ selectedAddress['level_2'] ? selectedAddress['level_2'].name : '请选择市' }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </li>
                  <li :class="{ active: currTabAddres == 'district' }" @click="currTabAddres = 'district'">
                    <div>
                      <span>{{ selectedAddress['level_3'] ? selectedAddress['level_3'].name : '请选择区/县' }}</span>
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane" :class="{ active: currTabAddres == 'province' }">
                    <ul class="province">
                      <li v-for="(item, index) in provinceArr" :key="index"
                        :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
                        <span @click="getAddress('city', item)">{{ item.name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane" :class="{ active: currTabAddres == 'city' }">
                    <ul class="city">
                      <li v-for="(item, index) in cityArr" :key="index"
                        :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
                        <span @click="getAddress('district', item)">{{ item.name }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane" :class="{ active: currTabAddres == 'district' }">
                    <ul class="district">
                      <li v-for="(item, index) in districtArr" :key="index"
                        :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
                        <span @click="getAddress('community', item)">{{ item.name }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="item-line service">
            <dt>服务</dt>
            <dd>
              <span>
                由
                <router-link :to="'/shop-' + shopInfo.site_id" class="ns-text-color">{{ shopInfo.site_name
                }}</router-link>
                发货并提供售后服务
              </span>
            </dd>
          </dl>
          <hr class="divider" />

          <template v-if="wholesale && wholesale.price_array != null && wholesale.price_array.length > 1">
            <dl class="item-line wholesale">
              <dt>集采价</dt>
              <dd>
                <div style="width:100px;line-height:27px;float: left;text-align: center;color:#FF1C1C;font-size: 18px;"
                  v-for="p in wholesale.price_array">
                  <span style="font-size: 14px;">￥</span>{{ $util.money(p.price) }}
                </div>
              </dd>
            </dl>
            <dl class="item-line wholesale">
              <dt>数量</dt>
              <dd>
                <div style="width:100px;float: left;text-align: center;font-size: 12px;"
                  v-for="(p, index) in wholesale.price_array">
                  <template v-if="index === wholesale.price_array.length - 1">
                    ≥{{ p.num }}{{ goodsSkuDetail.unit }}
                  </template>
                  <template v-else>
                    {{ p.num }}-{{ wholesale.price_array[index + 1].num - 1 }}{{ goodsSkuDetail.unit }}
                  </template>
                </div>
              </dd>
            </dl>
            <hr class="divider" />
          </template>

          <div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
            <dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
              <dt>{{ item.spec_name }}</dt>
              <dd>
                <ul>
                  <li v-for="(item_value, index_value) in item.value" :key="index_value">
                    <div :class="{
                      'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
                      disabled: item_value['disabled'] || (!item_value['selected'] && specDisabled)
                    }" @click="changeSpec(item_value.sku_id, item_value.spec_id)">
                      <img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
                      <span>{{ item_value.spec_value_name }}</span>
                      <i class="iconfont iconduigou1 ns-text-color"></i>
                    </div>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>

          <div class="buy-number">
            <dl class="item-line">
              <dt>起订量</dt>
              <dd>
                <el-popover v-model="popoverShow" trigger="manual" placement="top" width="200">

                  <p style="text-align: center;color:#F56C6C">
                    <i class="el-icon-warning">此商品最小购买数量为{{ miniNumber }}{{ goodsSkuDetail.unit }}</i>
                  </p>

                  <el-input-number slot="reference" :min="miniNumber" :max="goodsSkuDetail.stock" v-model="number"
                    size="small" style="width: 110px;" @change="numberVerify" @focus="numberVerify"
                    @blur="numberVerify"></el-input-number>
                </el-popover>

                <span class="inventory">库存{{ goodsSkuDetail.stock }}{{
                  goodsSkuDetail.unit
                }}&nbsp;&nbsp;(库存不足联系客服)</span>
                <!-- 限购 -->
                <!-- <em>(每人限购1件)</em> -->
                <span
                  style="display: none; margin-left: 20px; width: 179px;height: 13px;font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #FD274A;">由官方旗舰店发货并提供售后服务</span>
              </dd>
            </dl>
          </div>

          <dl class="item-line buy-btn">
            <dt></dt>
            <dd>
              <template
                v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1 && goodsSkuDetail.stock > 0">
                <!-- plain  鼠标划入变换效果 -->
                <el-button type="primary" @click="buyNow">立即购买</el-button>
                <el-button type="primary" icon="el-icon-shopping-cart-2" v-if="goodsSkuDetail.is_virtual == 0"
                  @click="joinCart" style="background: #FF860F;">加入购物车
                </el-button>
              </template>
              <template v-else>
                <el-button type="info" plain disabled>库存不足</el-button>
              </template>
              <div href="javascript:;" class="go-phone">
                <span style="color: #666666;margin-right: 10px;">手机购买</span>
                <img src="@/assets/images/goods/qrcode.png" />
                <div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>
              </div>
            </dd>
          </dl>

          <!-- <dl class="item-line merchant-service" v-show="shopInfo.shop_baozh == 1 ||
            shopInfo.shop_qtian == 1 ||
            shopInfo.shop_zhping == 1 ||
            shopInfo.shop_erxiaoshi == 1 ||
            shopInfo.shop_tuihuo == 1 ||
            shopInfo.shop_shiyong == 1 ||
            shopInfo.shop_shiti == 1 ||
            shopInfo.shop_xiaoxie == 1
            ">
            <dt>商家服务</dt>
            <div>
              <dd v-if="shopInfo.shop_baozh == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="保证服务">保证服务</span>
              </dd>
              <dd v-if="shopInfo.shop_qtian == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费">7天退换</span>
              </dd>
              <dd v-if="shopInfo.shop_zhping == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="商品支持正品保障服务">正品保障</span>
              </dd>
              <dd v-if="shopInfo.shop_erxiaoshi == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="付款后2小时内发货">两小时发货</span>
              </dd>
              <dd v-if="shopInfo.shop_tuihuo == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="退货承诺">退货承诺</span>
              </dd>
              <dd v-if="shopInfo.shop_shiyong == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="试用中心">试用中心</span>
              </dd>
              <dd v-if="shopInfo.shop_shiti == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="实体验证">实体验证</span>
              </dd>
              <dd v-if="shopInfo.shop_xiaoxie == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="如有商品质量问题、描述不符或未收到货等，您有权申请退款或退货，来回邮费由卖家承担">消协保证</span>
              </dd>
            </div>
          </dl> -->
          <dl class="item-line merchant-service">
            <dt>商家服务</dt>
            <div>
              <dd v-if="goodsSkuDetail.goods_tuangou == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="">支持团购</span>
              </dd>
              <dd v-if="goodsSkuDetail.goods_qtian == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费">7天退换</span>
              </dd>
              <dd v-if="goodsSkuDetail.goods_fupin == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="">扶贫宝贝</span>
              </dd>
              <dd v-if="goodsSkuDetail.goods_yanxuan == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="">纳福严选</span>
              </dd>
              <dd v-if="goodsSkuDetail.goods_yuyue == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="">预约送货</span>
              </dd>
              <dd v-if="goodsSkuDetail.goods_quanqiu == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="">全球精品</span>
              </dd>
              <dd v-if="goodsSkuDetail.goods_ziti == 1">
                <i class="el-icon-success"></i>
                <span class="ns-text-color-gray" title="">门店自提</span>
              </dd>
              <dd v-if="!goodsSkuDetail.goods_qtian">
                <i class="el-icon-remove-outline" style="color:#000"></i>
                <span class="ns-text-color-gray" title="">不支持七天无理由退货</span>
              </dd>
            </div>
          </dl>
        </div>

        <goods-history style="position: absolute;top:0;right: 0;" />
      </div>
    </div>
    <!--        传入当前商品价格，再传给notice组件-->
    <!--        这个molsnotice是属于detail组件的，因为this绑定在了当前组件上-->
    <!--        molsnotice初始化在了detail.js上-->
    <div class="clearfix" style="width: 1200px;margin: 0 auto;margin-bottom: 20px;">
      <return-policy :discount-price="goodsSkuDetail.discount_price" :join-cart="joinCart.bind(this)"
        :molsnotice="molsnotice" :current-sku-id="this.goodsSkuDetail.sku_id" />


      <!-- 组合套餐 -->
      <el-tabs class="bundling-wrap" v-model="tabBundling" @tab-click="bundlingChange"
        v-if="addonIsExit.bundling && bundling.length && bundling[0].bl_name">
        <el-tab-pane :label="item.bl_name" :name="'bundling_' + item.bl_id" v-for="(item, index) in bundling"
          :key="index">
          <div class="master">
            <div class="sku-img"><img :src="$img(goodsSkuDetail.sku_image, { size: 'mid' })" /></div>
            <div class="sku-name">{{ goodsSkuDetail.sku_name }}</div>
            <div class="sku-price ns-text-color">￥{{ goodsSkuDetail.price }}</div>
            <i class="el-icon-plus"></i>
          </div>
          <div class="operation">
            <div class="price-wrap">
              <span>组合套餐价</span>
              <strong class="bl-price ns-text-color">￥{{ item.bl_price }}</strong>
            </div>
            <el-button type="primary" size="medium" @click="$router.pushToTab('promotion/combo-' + item.bl_id)">立即购买
            </el-button>
            <i class="equal">=</i>
          </div>

          <div class="suits">
            <ul>
              <li v-for="(goods, goods_index) in item.bundling_goods" :key="goods_index"
                @click="$router.pushToTab({ path: '/sku-' + goods.sku_id })">
                <div class="sku-img"><img :src="$img(goods.sku_image, { size: 'mid' })" /></div>
                <div class="sku-name">{{ goods.sku_name }}</div>
                <div class="sku-price ns-text-color">￥{{ goods.price }}</div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="detail-wrap">

        <div class="clearfix" style="float: left;width:198px;">
          <customer-service-center :shop-info="shopInfo" :service-link="service_link" />
          <goods-recommend class="clearfix" style="background: white;margin-top: 20px;float: left;border:0;" />
        </div>

        <el-tabs class="goods-tab" v-model="tabName" type="card" @tab-click="tabChange">
          <el-tab-pane label="商品详情" name="detail" style="padding: 0 114px">
            <div v-html="goodsSkuDetail.goods_content"></div>
          </el-tab-pane>
          <el-tab-pane label="售后保障" name="after_sale" class="after-sale" style="padding: 0 20px">
            <div v-html="service"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!--联系客服弹窗-->
      <servicerMessage ref="servicerMessage" class="kefu"
        :shop="{ shop_id: shopInfo.site_id, logo: shopInfo.logo, shop_name: shopInfo.site_name }"></servicerMessage>
    </div>
  </div>
</template>

<script>
import PicZoom from 'vue-piczoom';
import detail from './detail';
import GoodsHistory from '@/components/GoodsHistory';
import servicerMessage from "@/components/message/servicerMessage";
import CustomerServiceCenter from "@/components/CustomerServiceCenter"
import ReturnPolicy from "@/components/ReturnPolicy"
import BreadCrumbs from "@/components/BreadCrumbs"
import GoodsRecommend from '@/components/GoodsRecommend'

export default {
  name: 'detail',
  components: {
    ReturnPolicy,
    CustomerServiceCenter,
    PicZoom,
    GoodsHistory,
    servicerMessage,
    BreadCrumbs,
    GoodsRecommend
  },
  mixins: [detail]
};
</script>
<!--不带scope，我的样式就不会对子组件有影响-->
<style lang="scss">
@import './detail.scss';

.goods-detail .el-tabs__nav-scroll {
  background-color: #EBEBEB;
  border: 0;
}



.sale-rule-content {
  font-size: 12px;
  background-color: #FFF;
  width: 508px;
  border: solid 1px #D7D7D7;
  position: absolute;
  z-index: 4;
  top: 5px;
  left: -5px;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, .05)
}

.sale-rule-content .sale-name {
  line-height: 16px;
  color: #df3033;
  border: 1px solid #df3033;
  height: 16px;
  padding: 2px 3px;
  margin-right: 10px;
}

.sale-rule-content .title {
  line-height: 20px;
  background-color: #F5F5F5;
  height: 20px;
  padding: 5px;
  border-bottom: solid 1px #D6D6D6
}

.sale-rule-content .title span {
  vertical-align: top
}

.sale-rule-content .title strong {
  font-weight: 400;
  color: #FF875A;
  margin: 0 2px
}

.sale-rule-content .title a {
  float: right
}

.sale-rule-content .content {
  padding: 5px 0 5px 60px
}

.sale-rule-content .bottom {
  border-top: solid 1px #D7D7D7;
  padding: 8px 0;
  overflow: hidden
}

.sale-rule-content .bottom a {
  color: #005EA6;
  text-decoration: none;
  float: right;
  margin-right: 10px
}

.sale-rule-content .mjs-tit {
  font-weight: 600
}

.sale-rule-content .mjs-tit time {
  font-weight: 400;
  color: #999
}

.sale-rule-content .mjs-info li {
  line-height: 24px;
  display: block;
  height: 24px;
  margin-bottom: 2px
}

.mj-coupon {
  margin-top: 10px;

  >div {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    dd {
      margin-right: 20px;
    }
  }

  p {
    display: inline-block;
    position: relative;
    line-height: 18px;
  }

  span {
    width: 100px;
    padding: 2px 10px;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
    background-color: $base-color;
    -webkit-mask-box-image: url(../../../assets/images/goods/coupon.png);
  }

  label {
    line-height: 16px;
    position: absolute;
    left: 10px;
    top: 1px;
  }
}


</style>
