<template>
  <div id="notice-main">
    <div id="mols-header">
      <span>提醒</span>
      <span @click="displaymeMth" class="rotatespan">X</span>
    </div>

    <template v-if="!molsNeedle">
      <div id="mols-center" v-if="molsnotice">
                <span>
                    <i class="el-icon-check"></i>
                    加入购物车成功
                </span>
        <span>目前商品共<span style="color: red">1</span>件，合计：<span style="color: red">￥{{ DiscountPrice }}</span></span>
      </div>
      <!--        !notice:F5->sessionStorage===null-->
      <div v-else id="mols-center">正在加入购物车中。。。</div>
    </template>
    <template v-else>
      <div style="display: flex;align-items: center;flex-direction: column;justify-content: center;margin: 13px auto">
        <p>加入购物车失败，</p>
        <p>购物车已经存在该样品</p>
      </div>
    </template>

    <div id="mols-footer">
      <button class="b1">
        <router-link to="/cart">
          进入购物车
        </router-link>
      </button>
      <button class="b2" @click="displaymeMth">
        继续购物
      </button>
    </div>
  </div>
</template>
<!--创建一个变量，用于侦听在count的变化，cont每变化一次，侦听函数都会对其加句号个数-->
<script>

import {cartList} from "@/api/goods/cart"

export default {
  name: "notice",
  props: {
    molsnotice: {
      type: Boolean,
      require: true
    },
    DiscountPrice: {
      type: String,
      require: true
    },

    joinCart: {
      type: Function,
      require: true
    },
    currentSkuId: {
      type: Number,
      require: true
    }
  },
  emits: ['displayme'],
  data: () => {
    return {
      //true购物车有 false购物车没有
      molsNeedle: false
    }
  },
  methods: {
    displaymeMth() {
      this.$emit('displayme')
    },
    molsGetCartList() {
      cartList().then(res => {
        let list = res.data;
        let sku = null;
        list.some(tmp => {
          if (tmp.sku_id == this.currentSkuId) {
            sku = tmp;
            return true;
          }
        })
        if (sku) {
          this.molsNeedle = true
        } else {
          this.molsNeedle = false
          this.joinCart()
        }
      }).catch(rej => {
        this.displaymeMth()
        if (rej.message == 'token不存在') {
          this.$router.pushToTab('/login')
        } else {
          this.$message.error(rej.message);
        }
      })
    }
  },
  // lifeCycle:声明钩子
  created() {
    this.molsGetCartList()
  },
  beforeDestroy() {
    console.log('Mols write')
  }
}

</script>

<style scoped lang="scss">
#notice-main {
  background-color: white;
  width: 280px;
  height: 180px;
  box-shadow: 3px 3px 3px 1px rgba(220, 220, 220, 0.99);
  border-radius: 5px;

  #mols-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    background-color: lightgrey;
    padding: 0 10px;
    line-height: 38px;
    //父亲权重只有1
    :nth-child(1) {
      font-size: 14px;
      color: #4e4e4d;
      float: left;
    }

    :nth-child(2) {
      cursor: pointer;
      font-size: 20px;
      font-weight: 900;
      color: #4e4e4d;
      float: right;
    }

  }

  #mols-center {
    box-sizing: border-box;
    width: 100%;
    height: 85px;
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //line-height: 0;开了flex之后，行高关了会导致文字重叠，flex对item元素的盒子模式有影响
    :nth-child(1) {
      color: grey;
    }

    :nth-child(2) {
      color: grey;
    }
  }

  #mols-footer {
    box-sizing: border-box;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border: 0;
      cursor: pointer;
      width: 100px;
      height: 30px;
      transition: background-color 1.5s ease;
    }

    .b1 {
      border: 1px solid rgba(128, 128, 128, 0.66);
      margin-right: 5px;
    }

    .b1:hover {
      background-color: #8080808B;
    }

    a:hover {
      color: black;
    }

    .b2 {
      background-color: rgba(224, 75, 10, 0.78);
      color: white;
    }

    .b2:hover {
      background-color: #E04B0AFF;
    }
  }
}
</style>